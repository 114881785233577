import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import moment from 'moment';
import {Helmet} from "react-helmet";

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import Hero from '../Components/Hero';
import Player from '../Components/National/Player';

import { 
	Container,
	Box,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	TableCaption,
	TableContainer,
} from '@chakra-ui/react'

const Transfer = (props) => {
	const [post, setPost] = useState({
		title: 'Transfer Market',
		players : [],
		positions: ['T', 'LV', 'IV', 'RV', 'DM', 'LM', 'ZM', 'RM', 'OM', 'LS', 'MS', 'RS']});

	useEffect(() => {
			Promise.all([
				Axios.get('player/transferList/')
			])
			.then(([playerResponse]) => {
				setPost({ players: playerResponse.data.team,
					title: 'Transfer Market',
					positions: ['T', 'LV', 'IV', 'RV', 'DM', 'LM', 'ZM', 'RM', 'OM', 'LS', 'MS', 'RS']
				});
			}, (error) => {
			})
	}, []);

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'GBP',
		minimumFractionDigits: 0
	  });

	return(
		<>
			<Helmet>
				<title>Websoccer - Transfer Market</title>
			</Helmet>
			<Hero 
				FirstName={post.title}
				PageType="teams"
				safeMode={props.safeMode} />
			<Container
					maxW='container.4xl'
					pt='75px;'
					pb='75px;'
				>
				<Box boxShadow='md' bg='white' w='100%' p={20}>
					<Tabs isLazy>
						<TabList>
							<Tab>Goalkeepers</Tab>
							<Tab>Left Defence</Tab>
							<Tab>Central Defence</Tab>
							<Tab>Right Defence</Tab>
							<Tab>Defensive Midfield</Tab>
							<Tab>Left Midfield</Tab>
							<Tab>Central Midfield</Tab>
							<Tab>Right Midfield</Tab>
							<Tab>Attacking Midfield</Tab>
							<Tab>Left Forward</Tab>
							<Tab>Centre Forward</Tab>
							<Tab>Right Forward</Tab>
						</TabList>
						<TabPanels>
							{post.positions
								.map(positionKey => {
									return(		
									<TabPanel>
										<TableContainer>
											<Table variant='striped' colorScheme='gray.100'>
												<TableCaption>All Players</TableCaption>
												<Thead bg="blackAlpha.800">
													<Tr>
														<Th color="white"></Th>
														<Th color="white">Name</Th>
														<Th color="white">Team</Th>
														<Th color="white">Position</Th>
														<Th color="white">Main Position</Th>
														<Th color="white">Value</Th>
														<Th color="white">Price</Th>
														<Th color="white">Skill</Th>
														<Th color="white">Technique</Th>
														<Th color="white">Fitness</Th>
														<Th color="white">Stamina</Th>
														<Th color="white">Morale</Th>
														<Th color="white">Wage</Th>
													</Tr>
												</Thead>
												<Tbody>
												{post.players
													.filter(playerKey => playerKey.mainPosition === positionKey)
													.sort((a, b) => a.skill < b.skill)
													.map(playerKey => {
														let PositionTranslate = playerKey.position
														if (playerKey.position === "Torwart"){
															PositionTranslate = "Goalkeeper"
														} else if (playerKey.position === "Abwehr"){
																PositionTranslate = "Defender"
														} else if (playerKey.position === "Mittelfeld"){
																PositionTranslate = "Midfielder"
														} else if (playerKey.position === "Sturm"){
																PositionTranslate = "Forward"
														}
														let SecondPositionTranslate = playerKey.mainPosition
														if (playerKey.mainPosition === "T"){
																SecondPositionTranslate = "Goalkeeper"
															} else if (playerKey.mainPosition === "IV"){
																SecondPositionTranslate = "Central Defence"
															} else if (playerKey.mainPosition === "LV"){
																SecondPositionTranslate = "Left Back"
															} else if (playerKey.mainPosition === "RV"){
																SecondPositionTranslate = "Right Back"
															} else if (playerKey.mainPosition === "DM"){
																SecondPositionTranslate = "Defensive Midfield"
															} else if (playerKey.mainPosition === "RM"){
																SecondPositionTranslate = "Right Midfield"
															} else if (playerKey.mainPosition === "LM"){
																SecondPositionTranslate = "Left Midfield"
															} else if (playerKey.mainPosition === "OM"){
																SecondPositionTranslate = "Attacking Midfield"
															} else if (playerKey.mainPosition === "ZM"){
																SecondPositionTranslate = "Centre Midfield"
															} else if (playerKey.mainPosition === "LS"){
																SecondPositionTranslate = "Left Forward"
															} else if (playerKey.mainPosition === "MS"){
																SecondPositionTranslate = "Centre Forward"
															} else if (playerKey.mainPosition === "RS"){
																SecondPositionTranslate = "Right Forward"
															}
														return (
															<>
															<Player
																key={playerKey.id}
																id={playerKey.id}
																teamId={playerKey.teamId}
																firstname={playerKey.firstname}
																surname={playerKey.surname}
																team={playerKey.team}
																position={playerKey.position}
																mainposition={PositionTranslate}
																secondaryposition={SecondPositionTranslate}
																skill={playerKey.skill}
																technique={playerKey.technique}
																fitness={playerKey.fitness}
																stamina={playerKey.stamina}
																morale={playerKey.morale}
																image={playerKey.image}
																injured={formatter.format(playerKey.wage)}
																suspension={formatter.format(playerKey.value)}
																price={formatter.format(playerKey.price)}
																deadline={moment.unix(playerKey.transferEnd).format("DD/MMM hh:mm")}
																icon={faCheckCircle}
																iconClass="success"
																safeMode={props.safeMode}/>
															</>
														)
													})}	
												</Tbody>
											</Table>
										</TableContainer>
									</TabPanel>
									)
								})
							}
						</TabPanels>
					</Tabs>
				</Box>
			</Container>
		</>
	)
}

export default Transfer;